import * as React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => {
  return (
    <Layout pageTitle="About Us">
      <h1>About Toast Technology Pty Ltd</h1>
      <p>Toast Technology Pty Ltd was founded on the belief that innovation and technology can drive positive change and transform businesses. Our founder, Nick Hortovanyi, is a passionate advocate for the power of technology to create new opportunities and solve complex problems.</p>
      <StaticImage
        alt="Nick in Montain View with Udactiy Self Driving Car"
        placeholder="blurred"
        height={400}
        style={{ margin: 0 }}
        src="../images/IMG_0595.png"
      />
      <p>In late 2017, Nick graduated from the world's first Self-Driving Car Engineer Nanodegree program from Udacity, based in Silicon Valley. He then went on to complete additional programs in Robotics Software Engineering, Deep Reinforcement Learning, Sensor Fusion and AI for Stock Trading.</p>
      <p>With a deep understanding of the potential of autonomous systems and AI, Nick saw an opportunity to create a consulting firm that could help businesses in Australia leverage these cutting-edge technologies to improve their operations and drive innovation. And so, Toast Technology Pty Ltd was born.</p>
      <p>At Toast Technology, we offer consulting services for projects related to Robotics, Computer Vision, Artificial Intelligence, and autonomous systems in the Australian market. We recognize that this is a nascent market in Australia and our early days, so we offer a range of tailored services to help each client initiate and implement their projects. We also have the capability to source sensors, technology, and expertise from around the globe to satisfy niche requirements that a client may have.</p>
      <p>We are committed to staying at the forefront of emerging technologies and are constantly seeking new opportunities to help businesses leverage the power of autonomous systems and AI. Contact us to learn more about how we can help your business drive innovation and transformation.</p>

    </Layout>
  )
}

export const Head = () => <Seo description="About Toast Technology Pty Ltd" title="About Us" />

export default AboutPage